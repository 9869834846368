import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { sizes } from '../../styles/sizes'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTwitter, faFacebook, faMedium, faLinkedin } from '@fortawesome/free-brands-svg-icons'

library.add(
    faMedium,
    faLinkedin,
    faFacebook,
    faTwitter
);


export const SocialList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: ${props => props.isCentered? "center" : "flex-end"};
    align-items: center;
    align-content: center;
    width: 100%;

    li {
        padding: ${sizes.sizeXS} 0 ${sizes.sizeXS} ${sizes.sizeS};
    }

    a {
        color: ${colors.colorTextDark};
        text-decoration: none;

        &:hover {
            color: ${colors.colorContentDark};
        }
    }
`