import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout.js"
import Seo from "../components/seo"
import { Row, BlogRow} from "../components/row/Row.js"
import { ReturnLink } from "../components/link/Link"
import { BlogTitle, SubHeading, Subtitle, Heading } from "../components/title/Title"
import { TextMedium, TextSmall } from "../components/text/Text"
import { Section, AboveFold } from "../components/section/Section"
import { Column100, Column50} from "../components/column/Column"
import homeRight from "../static/homehero.png"
import {ImageHome} from "../components/image/Image.js"
import { Breadcrumb } from "../components/breadcrumb/Breadcrumb"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock } from "@fortawesome/free-solid-svg-icons"
import { Author, DetailsWrapper } from "../components/author/Author"
import { colors } from "../styles/colors.js"
import Subscribe from "../components/subscribe/Subscribe"
import BlogBody from "../components/blogBody/BlogBody"
import get from 'lodash/get'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { faTwitter, faFacebook, faMedium, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { SocialList } from '../components/social/style.js'
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import authorImg from '../static/authors/andreeamacoveiciuc.jpeg'


library.add(
    faMedium,
    faLinkedin,
    faFacebook,
    faTwitter
);

export const pageQuery = graphql`
  query ContentfulBlogPost($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      id
      title
      tags
      subtitle
      slug
      date(formatString: "Do MMMM, YYYY")
      excerpt
      content {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            id
            title
            url
            file {
              url
            }
          }
          gatsbyImageData(width: 400)
        }
      }
      description {
        description
        childMarkdownRemark {
          timeToRead
          html
          rawMarkdownBody
        }
      }
      author {
        author
      }
      featuredImage{
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`


class BlogPostTemplate extends React.Component {
  render() {
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const title = this.props.data.contentfulPost.title
    const subtitle = this.props.data.contentfulPost.subtitle
    const blogContent = this.props.data.contentfulPost.content
    const excerpt = this.props.data.contentfulPost.excerpt
    const slug = this.props.data.contentfulPost.slug
    const authorName = this.props.data.contentfulPost.author.author
    const postDate = this.props.data.contentfulPost.date
    const timeToRead = this.props.data.contentfulPost.description.childMarkdownRemark.timeToRead
    const image = this.props.data.contentfulPost.featuredImage.resize.src 
    const icon = {faClock}

    const Bold = ({ children }) => <b>{children}</b>
    const Italic = ({ children }) => <i>{children}</i>

    const options = {
      renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
      },
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
          return <TextMedium textMedium={children} />
        },
        [BLOCKS.HEADING_2]: (node, children) => {
          return <Subtitle subtitle={children} />
        },
        [BLOCKS.HEADING_3]: (node, children) => {
          return <Heading heading={children} />
        },
        [BLOCKS.EMBEDDED_ASSET]: node => {
          const url = node.data.target.file.url
          const alt = node.data.target.title
          return <img alt={alt} src={url} />
        },
        [INLINES.HYPERLINK]: node => {
          const altText = node.content[0].value
          const url = node.data.uri
          return (
            <a className="link" href={url}>
              {altText}
            </a>
          )
        },
        "embedded-asset-block": (node, children) => {
          const alt = node.data.target.title
          const url = node.data.target.file.url
          const imgId = node.data.target.file.id

          return <div style={{"display":"flex", "flexFlow": "column", "justifyContent": "center", "alignItems": "center"}}>
            <img alt={alt} src={url} style={{"width": "80%", "margin-top": "1rem", "margin-bottom": "1rem"}}/>
            <p style={{"fontSize": "0.85rem"}}>Image: {alt}</p>
          </div>
         },
      },
      blockquote: node => {
        return <blockquote className="offer">{node.content}</blockquote>
      },
      hyperlink: node => {
        const altText = node.content[0].value
        const url = node.data.uri
        return (
          <a className="link" href={url}>
            {altText}
          </a>
        )
      },
    }

    return (     
      <Layout location={this.props.location} title={siteTitle}>
        <Seo
          title={title}
          description={excerpt}
          pathname={this.props.location.pathname}
          image={image}
          featuredImage={image}
        />
         
        <AboveFold isLight>
        <Row>
          <Breadcrumb>
            <li>
              <a href="/blog/">Blog</a>
            </li>
            <li>
              <a href={`${slug}`}>{title}</a>
            </li>
          </Breadcrumb>
        </Row>
          <Row isCentered>
          <Column50>
            <TextSmall textSmall={`Written on ${postDate} | ${timeToRead} min read` } /> 
            <BlogTitle isPrimary title={title}/>
            <SubHeading subheading={subtitle} />
            <Author
              authorName={`${authorName}`}
              avatarImg={authorImg}
              authorTitle="Strategist @School of Content"
            />
          </Column50>
          <Column50>
            <ImageHome src={image} alt="homepage" />
          </Column50>
        </Row>
      </AboveFold>

      <Section>
          <BlogRow isCentered>
              <BlogBody>
                {/* {documentToReactComponents(blogContent, options)} */}
                {/* {renderRichText(blogContent, options)} */}
                {blogContent?.raw && renderRichText(blogContent, options)}                
              </BlogBody>
              <ReturnLink
                isCentered
                anchortext="Return to all articles"
                href="/blog/"
              />
          </BlogRow> 
          <Subscribe />
        </Section>
      </Layout>
    )
  }
}

export default BlogPostTemplate